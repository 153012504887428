import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';


const backendURL = process.env.REACT_APP_BACKEND_URL;

// agentActions.js

export const createAgent = createAsyncThunk(
  'agents/create',
  async ({ name, status, workspace_id }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      
      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,  
        },
      };
      const response = await axios.post(
        `${backendURL}/agents`,
        { name, status, workspace_id }, // Send workspace_id here
        config
      );
      return response.data; 
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchAgents = createAsyncThunk(
  'agents/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      
      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Authorization': `Bearer ${accessToken}`,  
        },
      };

      const response = await axios.get(`${backendURL}/agents`, config);
      return response.data; 
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAgent = createAsyncThunk(
  'agents/update',
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };
      const response = await axios.put(
        `${backendURL}/agents/${id}`, // Utilisez l'ID de l'agent
        { name }, // Envoyer uniquement le nom
        config
      );
      return response.data; 
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAgent = createAsyncThunk(
  'agents/delete',
  async (id, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      // Effectuer la requête DELETE
      await axios.delete(`${backendURL}/agents/${id}`, config);
      return id; // Retourner l'ID pour que le reducer puisse le traiter
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const ADD_AGENT = 'ADD_AGENT';
export const addAgent = (agentData) => {
  return (dispatch) => {
    return axios.post(`${backendURL}/agents`, agentData)
      .then(response => {
        dispatch({ type: ADD_AGENT, payload: response.data });
      })
      .catch(error => {
        console.error('Error adding agent:', error);
        // Handle error appropriately
      });
  };
};